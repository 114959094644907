@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.password-hint {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 0.25rem;

  &__rule {
    display: flex;
    align-items: center;
    @include body-small-regular-v2;

    color: $grey-v2;

    svg {
      margin-right: 0.5rem;
    }

    &-valid {
      height: 12px;
      width: 12px;
      margin-right: 0.2rem !important;
      color: $success-v2;
    }
  }
}
