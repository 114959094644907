$letter-spacing-grostek: -0.32px;

@mixin header-1-v2 {
  font-family: Clother;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3rem;
  letter-spacing: 0;
  color: $black-v2;
}

@mixin header-2-v2 {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 2rem;
  letter-spacing: 0;
  color: $black-v2;
}

@mixin header-3-v2 {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  letter-spacing: 0;
  color: $black-v2;
}

@mixin body-large-v2 {
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  color: $black-v2;
}

@mixin body-v2 {
  font-family: Inter, sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0;
  color: $grey-darker-v2;
}

@mixin body-regular-v2 {
  @include body-v2;

  font-weight: 400;
}

@mixin body-medium-v2 {
  @include body-v2;

  font-weight: 500;
}

@mixin body-semi-bold-v2 {
  @include body-v2;

  font-weight: 600;
}

@mixin body-bold-v2 {
  @include body-v2;

  font-weight: 700;
}

@mixin body-small-v2 {
  font-family: Inter, sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0;
  color: $grey-darker-v2;
}

@mixin body-small-regular-v2 {
  @include body-small-v2;

  font-weight: 400;
}

@mixin body-small-semi-bold-v2 {
  @include body-small-v2;

  font-weight: 600;
}

@mixin button-text-v2 {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  color: $black-v2;
}

@mixin link-text-v2 {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  color: $primary-v2;
}

@mixin link-text-small-v2 {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0;
  color: $primary-v2;
}

@mixin title-1 {
  // Ex: Typology wide presentation
  font-family: Roslindale;
  font-size: 3.5rem;
  margin-bottom: 2rem;
}

@mixin title-2 {
  // Ex: My account
  font-family: Roslindale;
  font-size: 2.5rem;
}

@mixin title-3 {
  // Ex: Product Details Title
  font-family: Scto-Grotesk-A-Medium;
  letter-spacing: $letter-spacing-grostek;
  font-size: 1.625rem;
  max-width: 400px;
  color: black;
}

@mixin title-4 {
  // Ex: Admin / Settings / Groupes
  font-size: 1.625rem;
  @include font-bold;
}

@mixin notification {
  position: fixed;
  right: 2rem;
  top: 2rem;
  z-index: 50;
  min-width: 20rem;
}

@mixin font-title {
  font-family: Roslindale;
}

@mixin font-normal {
  font-family: Scto-Grotesk-A;
  letter-spacing: $letter-spacing-grostek;
}

@mixin font-medium {
  font-family: Scto-Grotesk-A-Medium;
  letter-spacing: $letter-spacing-grostek;
}

@mixin font-bold {
  font-family: Scto-Grotesk-A-Bold;
  letter-spacing: $letter-spacing-grostek;
}

@mixin rounded-light-border {
  border: 1px solid $border-gray-color;
  border-radius: 8px;
}

$tablet-width: 768px;
$desktop-width: 1025px;
$wide-screen: 1400px;

@mixin phone {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin notPhone {
  @media (min-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin notDesktop {
  @media (max-width: #{$desktop-width + 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin wideScreen {
  @media (min-width: #{$wide-screen}) {
    @content;
  }
}

@mixin double-row {
  max-height: 100%;
  max-width: 440px;
}

@mixin double-column {
  max-height: 450px;
  width: 100%;
}

@mixin input-style {
  width: 100%;
  height: 48px;
  padding: 1rem;
  @include font-normal;

  font-size: 1rem;
  background-color: white;
  border: 1px solid rgb(0 0 0 / 10%);
  border-radius: 4px;
  outline: none;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid rgb(0 0 0 / 20%);
  }
}
