@import '../../variables';
@import '../../mixins';

.Toastify {
  &__toast {
    width: 27.5rem;
    padding: 1.5rem;
    background-color: rgba($white-v2, 0.8);
    border-radius: 0.5rem;
    border: 1px solid $grey-lighter-v2;
    box-shadow: 0 4px 24px rgb(0 0 0 / 3%);

    &--error {
      .Toastify__toast-icon::before {
        background-image: url('../../../icons_V2/question-circle-red.svg');
      }
    }

    &--success {
      .Toastify__toast-icon::before {
        background-image: url('../../../icons_V2/check-mark-circle.svg');
      }
    }

    &--warning {
      .Toastify__toast-icon::before {
        background-image: url('../../../icons_V2/question-circle-yellow.svg');
      }
    }

    &--info {
      .Toastify__toast-icon::before {
        background-image: url('../../../icons_V2/question-circle-blue.svg');
      }
    }
  }

  &__toast-body {
    padding: 0;

    & div {
      @include body-regular-v2;
    }
  }

  &__toast-icon {
    &::before {
      content: '';
      background-size: 1.25rem 1.25rem;
      width: 1.25rem;
      height: 1.25rem;
    }

    margin-right: 1rem;

    & svg {
      display: none;
    }
  }

  &__close-button {
    color: $grey-v2;
    opacity: 1;
    margin: auto;
    position: relative;
    width: 1rem;
    height: 1rem;

    &::after {
      top: 0;
      right: 0;
      content: '';
      background-image: url('../../../icons_V2/cross.svg');
      width: 1rem;
      height: 1rem;
      background-size: 1rem 1rem;
      position: absolute;
    }

    & svg {
      display: none;
    }
  }
}
