@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

.signup-form-V2 {
  &__container {
    margin-top: 3rem;
    margin-bottom: 3rem;
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__content {
    display: flex;
    flex-direction: column;

    &-google {
      margin-bottom: 1rem;
    }
  }

  &__cgu {
    font-size: 0.75rem;
  }

  &__zip {
    @include notDesktop {
      flex-grow: 2;
    }
  }

  &__city {
    @include notDesktop {
      flex-grow: 3;
    }
  }

  &__bottom-actions {
    text-align: center;
    margin-top: 1.5rem;

    &__text {
      @include body-regular-v2;

      margin-bottom: 0.5rem;
    }

    &__sign-up {
      @include link-text-small-v2;
    }
  }

  &__link {
    @include link-text-small-v2;
  }
}

.separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;

  &__content {
    margin: 0 1rem;
    color: rgb(0 0 0 / 40%);
  }

  &__line {
    flex: 1;
    border-top: 1px solid#dfe6ed;
  }
}
