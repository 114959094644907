@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

.authentication-layout {
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  background-color: $beige-v2;

  @include notDesktop {
    flex-direction: column;
    padding-bottom: 3rem;
  }

  &__image {
    width: 27rem;
    height: 58rem;
    max-height: calc(100vh - 6rem);
    margin: auto 3rem;
    display: flex;
    border-radius: 0.75rem;
    background-image: url("https://storage.googleapis.com/fleex-assets/images/authentication/sign.jpg");
    background-size: cover;
    background-repeat: no-repeat;

    @include notDesktop {
      display: none;
    }

    &__content {
      width: 21rem;
      height: 52.25rem;
      max-height: calc(100% - 6rem);
      margin: auto;
      border-radius: 0.75rem;
      backdrop-filter: blur(6px);
      background-color: rgb(43 49 48 / 30%);
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__logo {
        color: $white-v2;
        margin-bottom: 0;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &__container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__content {
    overflow: visible;
    width: 100%;
    max-width: 29.125rem;
    margin: auto;

    @include desktop {
      max-height: 100vh;
    }

    @include notDesktop {
      max-width: 100%;
      padding: 1.5rem;
      margin: 0;
      justify-content: unset;
    }
  }

  &__testimonials {
    color: white;

    &__picture {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      margin-bottom: 1rem;
    }

    &__name {
      @include body-large-v2;

      color: white;
    }

    &__job {
      @include body-regular-v2;

      margin-bottom: 0.75rem;
      color: white;
    }

    &__company {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
      margin-bottom: 2rem;
      width: auto;
      max-height: 1.5rem;
    }

    &__text {
      font-family: Inter, sans-serif;
      letter-spacing: 0;
      font-size: 1.125rem;
      line-height: 1.625rem;
    }
  }

  &__partners {
    margin-bottom: 1rem;
    color: white;

    &__text {
      @include body-regular-v2;

      color: white;
      text-align: center;
      margin-bottom: 0.875rem;
    }

    &__logos {
      opacity: 0.7;
      display: flex;
      justify-content: center;

      &__svg {
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
        margin-right: 1rem;
        max-height: 1rem;
        width: auto;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__mobile-icon {
    color: $primary-v2;
    margin: 1.5rem;
  }

  &__title {
    @include header-1-v2;

    margin-bottom: 1rem;
  }

  &__subtitle {
    @include body-regular-v2;

    margin-bottom: 2rem;

    @include notDesktop {
      margin-top: 1rem;
    }
  }
}
