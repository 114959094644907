@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.vertical-tabs-large {
  &.vertical-tabs {
    min-width: 14.5625rem;
    margin: 2rem 1rem;
  }

  .vertical-tabs {
    &__title {
      padding-left: 1rem;
      margin-bottom: 2.5rem;
    }

    &__tab {
      margin-bottom: 0.25rem;
      padding: 1rem;

      &__content {
        display: flex;
        overflow: hidden;
        align-items: center;
      }

      &__text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &.current {
        @include body-v2;
      }
    }

    &__expandable {
      &-subsections {
        .vertical-tabs {
          &__tab {
            margin-bottom: 0.25rem;
            padding: 1rem;
            padding-left: 2.25rem;
          }
        }
      }
    }
  }
}

.vertical-tabs {
  width: 14.3125rem;

  &-arrow {
    display: flex;
    align-items: center;
  }

  &__title {
    margin-bottom: 1.5rem;

    &.large-title {
      @include header-2-v2;
    }

    &:not(.large-title) {
      @include header-3-v2;
    }
  }

  &__tab {
    margin-bottom: 0.5rem;
    padding: 0.75rem 1rem;
    display: block;
    @include body-medium-v2;

    border-radius: $border-large-radius;

    &:hover,
    &.current {
      @include body-v2;

      color: $primary-v2 !important;
      background-color: rgb(18 55 118 / 5%);
    }

    &.current-parent,
    &.current {
      @include body-semi-bold-v2;

      color: $primary-v2 !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__expandable {
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-arrow {
      transition: transform 0.2s ease-in-out;

      &.expanded {
        transform: rotate(90deg);
      }
    }

    &-subsections {
      .vertical-tabs {
        &__tab {
          margin-bottom: 0.5rem;
          padding: 0.75rem 1rem;
          padding-left: 2rem;
          line-height: 1.25rem;
          color: $grey-darker-v2;
          display: none;

          &.expanded {
            display: block;
          }

          &.current {
            @include body-medium-v2;
          }
        }
      }
    }
  }
}
