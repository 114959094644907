@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

.signup-company-client-success-V2 {
  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    @include header-1-v2;

    margin-bottom: 1rem;
  }

  &__subtitles {
    @include body-regular-v2;
  }

  &__separator {
    width: 100%;
    height: 1px;
    background-color: $grey-lighter-v2;
  }

  &__email {
    @include body-semi-bold-v2;
  }

  &__subtitle {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 2rem;
    }
  }

  &__bottom {
    margin-top: 2rem;
    text-align: center;

    &__text {
      margin-bottom: 0.5rem;
    }

    &__link {
      @include link-text-small-v2;
    }

    &__back {
      margin-bottom: 1.5rem;
    }
  }
}
