@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

.file-picker {
  width: 30.3125rem;

  &__dropzone {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #fcfcfc;
    text-align: center;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23E7E3E3FF' stroke-width='2' stroke-dasharray='11%2c 8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
    border-radius: 0.5rem;
    margin-bottom: 1rem;

    &--hover-accept {
      background-color: $primary-lightest-v2;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23A9B7CE' stroke-width='2' stroke-dasharray='11%2c 8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
      transition: 150ms;
    }
  }

  &__label {
    display: flex;
    flex-direction: column;

    &__label {
      @include link-text-v2;

      color: $grey-light-v2;
    }

    &__or {
      @include body-regular-v2;

      color: $grey-light-v2;
    }

    &__constraints {
      @include body-small-v2;

      margin-top: 1rem;
      color: $grey-light-v2;
    }

    &__browse {
      color: $primary-v2;
      border: none;
      background-color: transparent;
      @include link-text-v2;

      margin: auto;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  &__files {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $grey-lighter-v2;
    border-radius: 0.5rem;
    padding: 0.6875rem 1rem;
    width: 100%;
    margin-bottom: 1rem;
    height: 4.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    &__image {
      border-radius: 0.5rem;
      max-height: 3rem;
      width: 3rem;
    }

    &__remove-button {
      background-color: transparent;
      border: none;

      &:hover {
        cursor: pointer;
      }
    }

    &__name {
      @include body-regular-v2;

      color: $grey-darker-v2;
    }

    &__icon {
      color: $grey-v2;
    }
  }
}
