@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

.signin-form-V2 {
  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-google {
      margin-bottom: 1rem;

      svg {
        margin-right: 1rem;
      }
    }
  }

  &__remember-me {
    @include notDesktop {
      margin: auto;
    }
  }

  &__password_forgotten {
    @include link-text-small-v2;

    align-self: flex-end;

    @include notDesktop {
      justify-content: end;
    }
  }

  &__switch {
    margin: 2rem auto;
    line-height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @include link-text-small-v2;

    cursor: pointer;

    svg {
      margin-right: 0.3rem;
    }
  }

  &__bottom-actions {
    text-align: center;

    &__text {
      @include body-regular-v2;

      margin-bottom: 0.5rem;
    }

    &__sign-up {
      @include link-text-small-v2;
    }
  }
}

.sso-form-V2 {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    @include header-1-v2;

    margin-bottom: 1rem;
    align-self: flex-start;
  }

  &__subtitle {
    @include body-regular-v2;

    margin-bottom: 2rem;
  }

  &__submit {
    margin-top: 2rem;
  }

  &__back {
    margin-top: 2rem;
    @include link-text-small-v2;
  }
}

.separator-V2 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;

  &__content {
    margin: 0 1rem;
    @include body-small-v2;

    color: $grey-light-v2;
  }

  &__line {
    flex: 1;
    border-top: 1px solid $grey-lighter-v2;
  }
}
