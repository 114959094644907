@import "../../../assets/styles/mixins";

.wizard-form-step-v2 {
  &__inputs-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__inputs-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    > .wizard-form-step-v2 {
      &__input {
        margin-left: 1rem;

        &:first-child {
          margin: 0;
        }
      }
    }
  }

  &__input {
    flex: 1;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__submit {
    margin-top: 2rem;
  }

  &__back {
    margin-top: 1.5rem;
    width: 100%;
    justify-content: center;
  }
}
