@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.toggle-switch {
  display: flex;
  flex-direction: column;
  line-height: normal;

  &__name {
    margin-bottom: 0.75rem;
    @include body-large-v2;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.clickable {
      cursor: pointer;
    }
  }

  &__label {
    @include body-v2;

    margin-left: 0.5rem;
  }

  &__button {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    width: 2rem;
    height: 1.125rem;

    &-checkbox {
      display: none;
    }

    &-label {
      display: block;
      overflow: hidden;
      cursor: pointer;
      border-radius: 1.125rem;

      &.is-disabled {
        background-color: #ddd;
        cursor: not-allowed;
      }
    }

    &-inner {
      display: block;
      width: 200%;
      margin-left: -100%;
      transition: margin 0.2s ease;

      &::before,
      &::after {
        display: block;
        float: left;
        width: 50%;
        padding: 0;
        height: 1.125rem;
      }

      &::before {
        content: attr(data-yes);
        background-color: $secondary-v2;
      }
    }

    &-inner::after {
      content: attr(data-no);
      background-color: $grey-lighter-v2;
    }

    &-switch {
      display: block;
      width: 0.875rem;
      height: 0.875rem;
      margin: 2px;
      background: #fff;
      position: absolute;
      border-radius: 50%;
      transition: all 0.3s ease-in;
    }

    &-checkbox:checked + &-label {
      .toggle-switch__button-inner {
        margin-left: 0;
      }

      .toggle-switch__button-switch {
        margin-left: 1rem;
      }
    }
  }
}
