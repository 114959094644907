@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

.select-dropdown {
  width: 100%;
  padding: 0.5rem;
  position: absolute;
  top: calc($input-lg-v2 + 0.5rem);
  left: 0;
  z-index: 2;
  border: 1px solid $grey-lighter-v2;
  border-radius: $border-large-radius;
  box-shadow: 0 4px 24px rgb(0 0 0 / 3%);
  background-color: $white-v2;

  &__options {
    max-height: 12rem;
    overflow: scroll;
  }

  &__element {
    display: flex;
    align-items: center;
    padding: 0.75rem;
    @include body-regular-v2;

    &:hover {
      background-color: rgba($primary-v2, 0.05);
      border-radius: 0.375rem;
      cursor: pointer;
    }

    &__add {
      display: flex;
    }

    &__checkbox,
    &__add {
      margin-right: 0.5rem;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;

    & > button:first-child {
      margin-right: 0.5rem;
    }
  }
}

.color-dropdown {
  &__element {
    &-indicator {
      margin-right: 0.5rem;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
    }
  }
}
