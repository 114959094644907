/*  BACKGROUND  */
$background-main-color: #f8f3ef;
$background-secondary-color: #faf7f1;
$background-error-color: #e4b2b2;
$background-pink: #dbaea3;
$background-disabled: rgb(0 0 0 / 5%);
$background-danger: rgb(228 90 90 / 10%);

/*  BORDER  */
$border-main-color: #efe6de;
$border-secondary-color: #daaea3;
$border-error-color: rgb(228 90 90 / 30%);
$border-success-color: rgb(118 185 58 / 30%);
$border-disabled-color: rgb(0 0 0 / 20%);
$border-gray-color: #e5e5e5;
$border-pink-color: #dbaea3;
$border-main__V2: 1px solid #e6e8e8;

/*  TEXT  */
$text-primary-color: #293845;
$text-secondary-color: #767676;
$text-pink-color: #dbaea3;
$text-title-color: rgb(26 8 0 / 70%);
$border-large-radius: 8px;
$border-medium-radius: 6px;
$border-regular-radius: 4px;
$fleex-color: #e4a85a;
$danger-color: #e45a5a;
$success-color: #7dc337;
$warning-color: #ffdd57;
$background-color: #f5f5f5;
$navbar-width: 220px;
$layout-max-width: calc(1204px + (3rem * 2));

// FLEEX V2

// PRIMARY
$primary-darker-v2: #06142b;
$primary-dark-v2: #0e2c5e;
$primary-v2: #123776;
$primary-light-v2: #415f91;
$primary-lighter-v2: #a9b7ce;
$primary-lightest-v2: #eceff4;

// SECONDARY
$secondary-darker-v2: #562919;
$secondary-dark-v2: #be5c37;
$secondary-v2: #ee7345;
$secondary-light-v2: #f18f6a;
$secondary-lighter-v2: #f9ccbc;
$secondary-lightest-v2: #fef4f0;

// NEUTRAL
// GRAYSCALE
$black-v2: #1c1f24;
$black-bis-v2: #262930;
$grey-darkest-v2: #30343c;
$grey-darker-v2: #3f4550;
$grey-v2: #4f5664;
$grey-light-v2: #969aa3;
$grey-lighter-v2: #e3e4e7;
$grey-lightest-v2: #f1f2f3;
$white-v2: #fff;

// BEIGE
$beige-dark-v2: #f7f0ea;
$beige-v2: #fff9f3;
$beige-light-v2: #fcfbf8;

// SEMANTIC
// DANGER
$danger-darker-v2: #5e2b2b;
$danger-dark-v2: #9e4848;
$danger-v2: #c55a5a;
$danger-light-v2: #d17b7b;
$danger-lighter-v2: #eac3c3;
$danger-lightest-v2: #faf2f2;

// WARNING
$warning-darker-v2: #6b6039;
$warning-dark-v2: #b2a05f;
$warning-v2: #dfc877;
$warning-light-v2: #e5d392;
$warning-lighter-v2: #f3ebce;
$warning-lightest-v2: #fdfbf4;

// SUCCESS
$success-darker-v2: #1f6041;
$success-dark-v2: #33a16c;
$success-v2: #40c987;
$success-light-v2: #66d49f;
$success-lighter-v2: #baecd4;
$success-lightest-v2: #f0fbf6;

// INFO
$info-darker-v2: #133f67;
$info-dark-v2: #2069ac;
$info-v2: #2883d7;
$info-light-v2: #539cdf;
$info-lighter-v2: #b1d2f1;
$info-lightest-v2: #eef5fc;
$input-lg-v2: 3.5rem;
$input-md-v2: 3rem;
$input-sm-v2: 2.5rem;
