@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.sidemenu-mobile {
  padding: 1.5rem;

  &__link-logo {
    height: 1.5rem;
    width: 1.5rem;
    color: $primary-v2;
  }

  &__bar {
    display: flex;
    align-items: center;

    > :nth-child(2) {
      margin-left: auto;
    }

    > :not(:last-child) {
      margin-right: 1.5rem;
    }
  }

  &__container {
    position: fixed;
    left: 0;
    transform: translateY(calc(-100vh - 3rem));
    transition: 0.3s ease-in-out;

    &.visible {
      transform: translateY(-3rem);
    }

    .section-0 {
      transform: translateX(0);
    }

    .section-1 {
      transform: translateX(100vw);
    }

    .section-2 {
      transform: translateX(200vw);
    }

    &.shifted {
      .section-0 {
        transform: translateX(-100vw);
      }

      .section-1 {
        transform: translateX(0);
      }

      .section-2 {
        transform: translateX(100vw);
      }
    }

    &.shifted-2 {
      .section-0 {
        transform: translateX(-200vw);
      }

      .section-1 {
        transform: translateX(-100vw);
      }

      .section-2 {
        transform: translateX(0);
      }
    }
  }

  &__container,
  &__nav,
  &__section {
    height: 100vh;
    width: 100vw;
    z-index: 10;
    background: $beige-dark-v2;
    transition: 0.5s ease-in-out;
  }

  &__nav,
  &__section {
    position: absolute;
    overflow: auto;

    &__header {
      margin: 0 1.5rem;
      padding: 1.5rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $grey-lighter-v2;

      &-title {
        @include body-small-semi-bold-v2;

        color: $grey-darker-v2;

        > span {
          margin: 0 0.2rem;
          color: $grey-light-v2;
        }
      }
    }

    &__content {
      min-height: calc(100vh - 4.5rem);
      padding: 2rem 1.5rem;
      display: flex;
      flex-direction: column;
      overflow: auto;
    }

    &__item {
      height: 3rem;
      display: flex;
      align-items: center;
      @include body-medium-v2;

      color: $grey-darker-v2;

      &.selected {
        color: $primary-v2;
      }

      &.bottom-nav {
        margin-top: auto;
      }

      &:not(:last-child) {
        margin-bottom: 0.75rem;
      }

      &-icon {
        height: 48px;
        width: 48px;
        padding: 0.875rem 0.75rem;
        display: flex;
        align-items: center;
        border-radius: $border-large-radius;
        cursor: pointer;

        &.selected {
          background-color: $beige-v2;
          color: $primary-v2;

          svg {
            color: $primary-v2;
          }
        }
      }

      &-title {
        &.selected {
          color: $primary-v2;
        }
      }

      &-chevron {
        margin-left: auto;
      }
    }
  }

  &__nav__content {
    padding-bottom: 8rem;
  }

  &__nav__item-title {
    margin-left: 0.75rem;
    @include body-semi-bold-v2;
  }

  &__section__header {
    justify-content: space-between;
  }

  &__floating-content {
    height: fit-content;
    width: 90%;
    padding: 0.5rem;
    position: absolute;
    left: 5%;
    z-index: 10;
    box-shadow: 0 6px 8px rgb(0 0 0 / 5%);
    border-radius: $border-regular-radius;
    background: white;
    visibility: hidden;

    &.visible {
      visibility: visible;
    }

    a,
    label {
      @include body-regular-v2;

      padding: 0.75rem;
      display: block;
    }
  }
}
