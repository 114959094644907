@import "../../assets/styles/mixins";
@import "../../assets/styles/variables";

.error {
  display: flex;
  flex: 1;

  @include phone {
    padding: 0.5rem;
    flex-direction: column;

    .error__icon {
      max-width: 90vw;
      height: 200px;
    }
  }

  @include notPhone {
    .error__icon {
      margin-bottom: 2rem;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    @include header-1-v2;

    line-height: 3rem;
    font-size: 2.5rem;
    text-align: center;

    @include phone {
      line-height: 2rem;
      font-size: 1.5rem;
    }
  }

  &__text {
    max-width: 660px;
    margin: 2rem 0;
    @include body-regular-v2;

    text-align: center;
  }

  &-500 {
    height: 100vh;

    .error__content {
      height: 100%;
    }
  }
}
