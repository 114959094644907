@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

.incompatible-browser-page {
  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    filter: grayscale(40%);
    opacity: 0.8;
  }

  &__content {
    padding: 4rem;

    img {
      max-width: 150px;
    }

    h1 {
      @include font-title;

      color: $fleex-color;
      font-size: 5rem;
      margin: 5rem 0 2rem;
      max-width: 750px;

      @include tablet {
        margin: 3rem 0 1rem;
        font-size: 3rem;
      }

      @include phone {
        margin: 1rem 0;
        font-size: 2.5rem;
      }
    }

    p {
      color: white;
      font-size: 1.25rem;
      text-shadow: 1px 1px rgba($color: #000, $alpha: 25%);
      max-width: 1000px;

      &:last-child {
        margin-top: 2rem;
      }

      @include notDesktop {
        color: $text-primary-color;
        text-shadow: none;
      }
    }

    a {
      color: $fleex-color;
      text-decoration: underline;
    }
  }
}
