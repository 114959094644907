/* Typography Roslindale */
@font-face {
  font-family: Roslindale;
  src: url("./assets/fonts/RoslindaleDisplayCondensed-Regular.ttf") format("truetype");
}
@font-face {
  font-family: Roslindale-Light;
  src: url("./assets/fonts/RoslindaleDisplayCondensed-Light.ttf") format("truetype");
}
@font-face {
  font-family: Roslindale-Bold;
  src: url("./assets/fonts/RoslindaleDisplayCondensed-Bold.ttf") format("truetype");
}

/* Typography Scto Grotesk A */
@font-face {
  font-family: Scto-Grotesk-A;
  src: url("./assets/fonts/Scto\ Grotesk\ A\ Regular.otf") format("truetype");
}
@font-face {
  font-family: Scto-Grotesk-A-Medium;
  src: url("./assets/fonts/Scto\ Grotesk\ A\ Medium.otf") format("truetype");
}
@font-face {
  font-family: Scto-Grotesk-A-Bold;
  src: url("./assets/fonts/Scto\ Grotesk\ A\ Bold.otf") format("truetype");
}

/* Typography Inter */

@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter-Regular.ttf") format("truetype");
  font-weight: 400; /* normal */
}

@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

/* Typography Clother */

@font-face {
  font-family: Clother;
  src: url("./assets/fonts/Clother-Bold.woff2") format("woff2");
  font-weight: 700;
}

body,
textarea {
  margin: 0;
  font-family: Scto-Grotesk-A;
  letter-spacing: -0.32px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.form-item__error-message {
  font-size: 0.6875rem;
  min-height: 2rem;
}

.is-clickable {
  cursor: pointer;
}

@media (min-width: 1024px) {
  .fullpadding__desktop {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .fullmargin__desktop {
    margin-left: 3rem;
    margin-right: 3rem;
  }
}

@media (max-width: 1023px) {
  .only-desktop {
    display: none;
  }
}

@media (min-width: 768px) {
  .not-desktop {
    display: none;
    max-height: 0;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s !important;
}
