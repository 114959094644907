@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.side-menu {
  display: flex;
  position: relative;

  &__main {
    height: 100vh;
    min-width: 80px;
    padding: 2rem 0 2rem 1rem;
    z-index: 1;
    display: flex;
    flex-direction: column;
    background-color: $beige-dark-v2;

    .bottom-nav {
      margin-top: auto;
    }
  }

  &__link-logo {
    margin-left: 0.5rem;
    margin-bottom: 2.5rem;
    display: block;
    color: $primary-v2 !important;
  }

  &__icon {
    height: 52px;
    width: 48px;
    margin-bottom: 4px;
    padding: 0.875rem 0.75rem;
    display: flex;
    align-items: center;
    border-radius: $border-large-radius;
    cursor: pointer;
    transition: width ease-out 0.1s;

    svg {
      color: $grey-v2;
    }

    &:hover,
    &.selected {
      background-color: $beige-v2;
      color: $primary-v2;

      svg {
        color: $primary-v2;
      }
    }

    &.expanded {
      width: 64px;
      position: relative;
      background-color: $beige-v2;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      .reversed-radius {
        height: 1rem;
        width: 1rem;
        position: absolute;
        right: 0;
        background-color: $beige-v2;

        &__placeholder {
          height: 1rem;
          width: 1rem;
          background-color: $beige-dark-v2;
        }

        &.top {
          top: -1rem;

          .reversed-radius__placeholder {
            border-bottom-right-radius: $border-large-radius;
          }
        }

        &.bottom {
          bottom: -1rem;

          .reversed-radius__placeholder {
            border-top-right-radius: $border-large-radius;
          }
        }
      }
    }
  }

  &__section {
    height: 100vh;
    width: 0;
    min-width: 0;
    z-index: 0;
    overflow-y: auto;
    background-color: $beige-v2;
    transform: translateX(-100%);
    transition: 0.3s ease-in-out;

    &.visible {
      min-width: 265px;
      transform: translateX(0%);
    }

    &-item {
      padding: 1rem;
      display: block;
      font-size: 0.875rem;
      color: $grey-darker-v2;
      white-space: nowrap;
    }
  }

  &__subsection {
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      svg {
        height: 8px;
      }
    }

    &-content {
      padding-left: 1.25rem;
    }
  }

  &__floating-content {
    width: max-content;
    padding: 0.5rem;
    position: absolute;
    left: 6rem;
    transform: translateY(-50%);
    z-index: 1;
    box-shadow: 0 6px 8px rgb(0 0 0 / 5%);
    border-radius: $border-regular-radius;
    @include rounded-light-border;

    background: white;
    visibility: hidden;

    &.visible {
      visibility: visible;
    }

    a,
    label {
      @include body-regular-v2;

      padding: 0.75rem;
      display: block;
      border-radius: $border-regular-radius;
      cursor: pointer;

      &:hover {
        background: rgba($primary-v2, 0.05);
        color: $grey-darker-v2 !important;
      }
    }
  }
}
