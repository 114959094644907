@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

.query-handler__error {
  height: 100%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-message {
    margin: 1.5rem 0;
    @include header-3-v2;

    color: $grey-darker-v2;
  }
}
