@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.group-label {
  padding: 0.25rem 0.5rem;
  border: 1px solid $grey-lighter-v2;
  border-radius: $border-large-radius;
  display: inline-block;
  height: fit-content;
  @include body-medium-v2;
}

.group-list {
  width: 100%;

  & > span {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
}
