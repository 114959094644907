@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

.reset-form-V2 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__title {
    @include header-1-v2;

    margin-bottom: 1rem;
  }

  &__subtitles {
    p {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    margin-bottom: 2rem;
  }

  &__subtitle {
    @include body-regular-v2;

    margin-bottom: 2rem;

    &__link {
      @include link-text-small-v2;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
  }
}
