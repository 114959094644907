@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.checkbox-V2 {
  display: flex;
  align-items: center;
  cursor: pointer;

  &.checked {
    svg {
      height: 10px;
      width: 10px;
    }
  }

  &.disabled {
    opacity: 0.3;
    cursor: default;
  }

  &__element {
    height: 1rem;
    width: 1rem;
    min-width: 1rem;
    max-width: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-regular-radius;

    svg {
      height: 8px;
      width: 8px;
    }

    &.checked,
    &.indeterminate {
      background-color: $secondary-v2;
      color: white;
    }

    &.unchecked {
      border: 1px solid $grey-lighter-v2;
      background-color: $white-v2;
    }

    &.disabled {
      background-color: $grey-light-v2;
      border-color: $grey-light-v2;
    }
  }

  &__text {
    margin-left: 0.5rem;
    @include body-regular-v2;
  }

  &:hover {
    .checkbox-V2__element {
      &.checked,
      &.indeterminate {
        background-color: $secondary-dark-v2;
      }

      &.unchecked {
        border-color: $grey-light-v2;
      }

      &.disabled {
        background-color: $grey-light-v2;
        border-color: $grey-light-v2;
      }
    }
  }
}
