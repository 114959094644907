@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

.button-FL-V2 {
  display: inline-flex;
  align-items: center;
  padding: 0.75rem 1.25rem;
  border-radius: $border-large-radius;
  border: none;
  box-shadow: none;
  @include button-text-v2;

  color: $white-v2;
  cursor: pointer;
  transition: 0.2s ease;
  height: 3rem;

  & svg:first-child {
    margin-right: 0.625rem;
  }

  & svg:last-child {
    margin-left: 0.625rem;
  }

  &--primary {
    background-color: $primary-v2;

    &:hover:not(:disabled) {
      background-color: $primary-dark-v2;
    }

    &:disabled {
      background-color: $grey-v2;
      opacity: 0.2;
    }
  }

  &--secondary,
  &--filters {
    background-color: $white-v2;
    color: $primary-v2;
    border: 2px solid $grey-lighter-v2;

    svg {
      fill: $primary-v2;
    }

    &:hover:not(:disabled) {
      background-color: $grey-lightest-v2;
      border-color: $primary-lighter-v2;
    }

    &:disabled {
      opacity: 0.3;
      background-color: $grey-lightest-v2;
      color: $grey-v2;

      svg {
        fill: $grey-v2;
      }
    }
  }

  &--tertiary {
    background-color: white;
    @include body-large-v2;

    padding: 1rem;
    width: 100%;

    .content {
      flex: 1;
      text-align: left;
    }

    &:hover {
      background-color: rgba($primary-v2, 0.05);
    }
  }

  &--empty {
    color: $primary-v2;
    border: none;
    background-color: transparent;
    padding: 0;
    height: auto;

    &:hover:not(:disabled) {
      text-decoration: underline;
      text-underline-offset: 3px;
    }

    &:disabled {
      color: $grey-v2;
      opacity: 0.3;

      svg {
        fill: $grey-v2;
      }
    }
  }

  &--filters {
    border-width: 1px;
  }

  &:disabled {
    pointer-events: none;
    cursor: default;

    .badge {
      background-color: $grey-v2;
    }
  }

  .badge {
    margin-left: 0.625rem;
    background-color: $secondary-v2;
    color: $white-v2;
    border-radius: 7px;
    font-size: 0.625rem;
    line-height: 0.8125rem;
    width: 1rem;
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: $white-v2;
    }
  }
}

.full-width {
  width: 100%;
  justify-content: center;
}
