@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.tooltip-V2 {
  display: inline-block;
  position: relative;

  &__content {
    padding: 0.625rem;
    position: absolute;
    left: 50%;
    z-index: 100;
    @include body-regular-v2;

    background-color: white;
    border: 1px solid $grey-lighter-v2;
    border-radius: $border-large-radius;
    text-align: justify;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.4s ease-in;

    &.visible {
      opacity: 1;
    }

    &.top {
      bottom: 2.5rem;
    }

    &.right {
      left: calc(100% + 0.5rem);
      top: 50%;
      transform: translateX(0) translateY(-50%);
    }

    &.bottom {
      top: 2.5rem;
    }

    &.left {
      left: auto;
      right: calc(100% + 0.5rem);
      top: 50%;
      transform: translateX(0) translateY(-50%);
    }
  }

  &__arrow {
    position: absolute;

    &.arrow-top {
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }

    &.arrow-right {
      left: -17px;
      top: 50%;
      transform: translateX(0) translateY(-50%) rotate(90deg);
    }

    &.arrow-bottom {
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%) rotate(180deg);
    }

    &.arrow-left {
      right: -17px;
      top: 50%;
      transform: translateX(0) translateY(-50%) rotate(-90deg);
    }
  }
}
