@import '../../../assets/styles/mixins';
@import '../../../assets/styles/variables';

@mixin input-style($size) {
  width: 100%;
  height: $size;
  @if $size == $input-lg-v2 {
    padding: 1.0625rem 0.9375rem 0.625rem;
  } @else if $size == $input-md-v2 {
    padding: 0.8125rem 0.9375rem 0.625rem;
  } @else if $size == $input-sm-v2 {
    padding: 0.5625rem 0.6875rem 0.625rem;
  }

  &:-webkit-autofill {
    color: $grey-darker-v2 !important;
  }

  @include body-regular-v2;

  background-color: $white-v2;
  border: 0.0625rem solid $grey-lighter-v2;
  border-radius: $border-large-radius;
  transition: 0.2s ease;

  &--md {
    height: $input-md-v2;
  }

  &:hover {
    border: 0.0625rem solid $grey-light-v2;
  }

  &:focus:not(:disabled),
  &:active:not(:disabled),
  &:not(:placeholder-shown) {
    padding-top: 1.625rem;
  }

  &:focus:not(:disabled),
  &:active:not(:disabled) {
    border: 0.0625rem solid $primary-v2;
    outline: none;
  }

  &:focus:not(:disabled),
  &:active:not(:disabled),
  &:hover:not(:disabled) {
    box-shadow: 0 0 0 4px rgba($primary-v2, 0.2);
  }

  // Selectionne les labels lorsqu'on a le focus/active sur un input (sauf pour les select)
  // Selectionne les labels lorsque que le contenu n'est pas vide (:not(:placeholder-shown)) sauf pour les
  // select group et les select de taille sm

  /* stylelint-disable selector-class-pattern */
  &:active:not(:disabled, .form-element-v2__input--select) + .form-element-v2__label,
  &:focus:not(:disabled, .form-element-v2__input--select) + .form-element-v2__label,
  &:not(:placeholder-shown, .form-element-v2__input--select--group, .form-element-v2__input--select--sm)
    + .form-element-v2__label {
    /* stylelint-enable selector-class-pattern */

    @include body-small-regular-v2;

    color: $grey-light-v2;
    transform: translateY(-8px);
  }
}

.form-element-v2 {
  position: relative;
  line-height: 0;

  &__icon {
    position: absolute;
    right: 0;
    top: 0;
    margin: 1.125rem 1rem;

    & svg {
      color: $grey-light-v2;
    }

    &--left {
      left: 0;
      right: unset;
    }
  }

  &__group-label {
    margin-right: 0.5rem;
  }

  &__label {
    pointer-events: none;
    @include body-regular-v2;

    color: $grey-light-v2;
    transition: all 200ms;
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 1.125rem 1rem;

    &--wysiwyg {
      top: 7.75rem;
      @include body-small-regular-v2;

      color: $grey-light-v2;
    }

    &--checkbox {
      color: inherit;
      font-size: inherit;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &__content {
    min-height: 3rem;
    padding: 16px 0;
    font-size: 16px;
    line-height: 16px;
    @include font-normal;

    color: $text-primary-color;
    cursor: pointer;

    &.input-not-selectable,
    &__not-selectable {
      cursor: default;
    }

    &.textarea {
      white-space: pre-wrap;
    }
  }

  &__image-wrapper {
    width: 84px;
    height: 84px;
    display: inline-block;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  &__image {
    border-radius: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-image {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__wrapper {
      width: 84px;
      height: 84px;
      display: inline-block;
      margin: 0 16px;
      background-color: $background-color;

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    input:read-only {
      background-color: rgb(0 0 0 / 5%);
      border: 0.0625rem solid rgb(0 0 0 / 20%);
    }
  }

  &__radio-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__radio {
    appearance: none;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    outline: none;
    border: 1px solid $grey-lighter-v2;

    &::before {
      display: block;
      content: '';
      width: 60%;
      height: 60%;
      margin: 20% auto;
      border-radius: 50%;
    }

    &:checked::before {
      background: $secondary-v2;
    }

    &:checked {
      border-color: $secondary-v2;
    }

    &--error {
      border-color: $danger-v2;
      box-shadow: 0 0 0 2px rgb(197 90 90 / 20%);
    }

    &__label {
      @include body-regular-v2;

      margin-left: 0.5rem;
    }
  }

  &__multiselect {
    &__control {
      @include input-style($input-lg-v2);

      display: flex;
      align-items: center;
    }

    &__value-container {
      border: none;
    }

    &-error > div:first-child {
      border: 0.0625rem solid $border-error-color;
    }

    &__indicator {
      color: $text-primary-color;
    }

    &__menu {
      padding: 16px 16px 0;
    }

    &__option {
      margin-bottom: 8px;
      color: $text-primary-color;
      user-select: none;
      cursor: pointer;

      &:hover {
        color: var(--primary-color);
      }
    }

    &__multi-value__label {
      font-size: 16px;
    }
  }

  &__autocomplete {
    position: relative;

    &-input {
      @include input-style($input-lg-v2);

      &.error {
        border: 0.0625rem solid $border-error-color;
      }

      &:disabled {
        background-color: rgb(0 0 0 / 5%);
        border: 0.0625rem solid rgb(0 0 0 / 20%);
      }
    }

    &-options {
      position: absolute;
      top: calc(100% + 0.0625rem);
      z-index: 2;
      width: 100%;
      padding: 16px;
      background: white;
      border-radius: $border-regular-radius;
      box-shadow: 0 0 0 0.0625rem rgb(0 0 0 / 10%), 0 0.25rem 0.6875rem rgb(0 0 0 / 10%);
    }

    &-option {
      padding: 4px;
      color: $text-primary-color;
      cursor: pointer;

      &.current {
        background: rgb(0 0 0 / 10%);
      }

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &:hover {
        background: rgb(0 0 0 / 10%);
      }
    }

    &-empty {
      color: $text-primary-color;
    }
  }

  .DayPickerInput {
    color: initial;
    line-height: 1.5;

    input {
      @include input-style($input-lg-v2);
    }

    .DayPicker-Day--today {
      color: $primary-v2;
    }

    .DayPicker-Day--selected {
      color: white !important;
      background-color: $primary-v2 !important;
    }
  }

  &__input {
    @include input-style($input-lg-v2);

    position: relative;

    &::placeholder {
      opacity: 0.4;
      color: #000;
    }

    &:disabled {
      background-color: $grey-lightest-v2;
      border: 1px solid $grey-lighter-v2;
      opacity: 0.3;
    }

    &:disabled + .form-element-v2__label {
      color: $grey-v2;
      opacity: 0.3;
    }

    &.error {
      border: 0.0625rem solid $danger-v2;
      box-shadow: 0 0 0 4px rgba($danger-v2, 0.2);
    }

    &.success {
      color: $success-color;
      border: 0.0625rem solid $border-success-color;
    }

    &--select {
      position: relative;
      appearance: none;
      background-color: transparent;
      background-size: 1rem;
      background-repeat: no-repeat;
      background-position: right 0.9375rem center;
      padding-right: 3rem;
      text-overflow: ellipsis;

      &:hover {
        cursor: pointer;
      }

      &--group {
        padding-top: 0.875rem !important;
        padding-bottom: 0.875rem;
        align-items: center;
        display: flex;
      }

      &--sm {
        padding-right: 2.5rem !important;
      }
    }

    &--checkbox {
      // width: auto;
      margin-right: 8px;
      height: 16px;
      width: 16px;

      &-container {
        margin-right: 8px;
        color: white;

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        .checkmark {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: $border-regular-radius;
          border: 0.0625rem solid $border-gray-color;
          padding: 4px;

          &.error {
            border: 0.0625rem solid $border-error-color;
          }
        }

        &:hover input ~ .checkmark {
          background-color: #ccc;
        }

        input:checked ~ .checkmark {
          background-color: $secondary-v2;
        }

        .checkmark::after {
          content: '';
          position: absolute;
          display: none;
        }
      }
    }

    &--radio {
      height: fit-content;
      display: flex;
      flex-direction: column;

      > div {
        margin-bottom: 8px;
        display: flex;
        align-items: baseline;
        @include font-normal;

        input {
          margin-right: 8px;
        }
      }
    }

    &--textarea {
      min-height: 80px;
    }

    &--text {
      &.is-disabled {
        background-color: $background-disabled;
        border: 0.0625rem solid $border-disabled-color;
        cursor: not-allowed;
      }
    }

    &--password {
      position: relative;

      input {
        padding-right: 48px;
        @include input-style($input-lg-v2);
      }

      &-visible {
        color: $grey-light-v2;
        cursor: pointer;

        svg {
          margin: 1rem;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 1;
        }
      }
    }
  }

  &__wysiwyg-editor {
    &-input {
      @include input-style($input-lg-v2);

      height: auto;
      max-height: 18.75rem;
      padding: 0 16px;
      cursor: text;

      &.error {
        border: 0.0625rem solid $border-error-color;
      }

      &.onlyReadMode {
        background-color: $background-disabled;
        border: 0.0625rem solid $border-disabled-color;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }

  &__add {
    &-container {
      position: relative;

      &:hover {
        .form-element__add-widget {
          display: block;
        }
      }
    }

    &-widget {
      display: none;
      position: absolute;
      right: 24px;
      top: 8px;
      color: green;
      font-size: 24px;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__dynamic-hint {
    padding-top: 8px;
    font-size: 13px;
    color: $text-secondary-color;
  }

  &__hint {
    @include body-small-regular-v2;

    display: block;
    margin-top: 0.75rem;
    color: $grey-light-v2;
  }

  &__error {
    display: block;
    margin-top: 0.75rem;
    @include body-small-regular-v2;

    color: $danger-v2;
  }

  &--read-only {
    margin-bottom: 16px;
  }

  .select-separator {
    &::after {
      position: absolute;
      content: '';
      width: 1px;
      height: 2.25rem;
      background-color: $grey-lighter-v2;
      background-position: right center;
      top: 0.625rem;
      right: 3rem;
      z-index: 1;
    }
  }

  .select-dropdown {
    max-height: 20rem;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &--md {
    .form-element-v2__multiselect {
      &__control {
        @include input-style($size: $input-md-v2);
      }
    }

    .form-element-v2__autocomplete {
      &-input {
        @include input-style($size: $input-md-v2);
      }
    }

    .form-element-v2__icon {
      margin: 0.875rem 1rem;
    }

    .DayPickerInput {
      input {
        @include input-style($size: $input-md-v2);
      }
    }

    .form-element-v2__input {
      @include input-style($size: $input-md-v2);
    }

    .form-element-v2--password {
      input {
        @include input-style($size: $input-md-v2);
      }
    }

    .form-element-v2__wysiwyg-editor {
      &-input {
        @include input-style($size: $input-md-v2);
      }
    }

    .form-element-v2__label {
      padding: 0.875rem 1rem;
    }

    .select-separator {
      &::after {
        height: 1.75rem;
      }
    }

    .select-dropdown {
      top: calc($input-md-v2 + 0.5rem);
    }
  }

  &--sm {
    .form-element-v2__multiselect {
      &__control {
        @include input-style($size: $input-sm-v2);
      }
    }

    .form-element-v2__autocomplete {
      &-input {
        @include input-style($size: $input-sm-v2);
      }
    }

    .DayPickerInput {
      input {
        @include input-style($size: $input-sm-v2);
      }
    }

    .form-element-v2__input {
      @include input-style($size: $input-sm-v2);

      &--select {
        background-position: right 0.6875rem center;
      }

      &:not(:placeholder-shown) + .form-element-v2__label {
        @include body-regular-v2;

        transform: none;
      }

      &:focus:not(:disabled),
      &:active:not(:disabled),
      &:not(:placeholder-shown) {
        padding-top: 9px;
      }

      /* stylelint-disable selector-class-pattern */
      &:not(:placeholder-shown, .form-element-v2__input--select--group) + .form-element-v2__label {
        /* stylelint-enable selector-class-pattern */
        &::after {
          content: '\0000A0:';
          position: relative;
        }
      }
    }

    .form-element-v2--password {
      input {
        @include input-style($size: $input-sm-v2);
      }
    }

    .form-element-v2__wysiwyg-editor {
      &-input {
        @include input-style($size: $input-sm-v2);
      }
    }

    .form-element-v2__label {
      padding: 0.625rem 0.75rem;
      width: fit-content;
    }

    .select-separator {
      &::after {
        height: 1.5rem;
        top: 0.5rem;
        right: 2.5rem;
      }
    }

    .select-dropdown {
      top: calc($input-sm-v2 + 0.5rem);
    }
  }

  .DayPickerInput input.error {
    border: 0.0625rem solid $danger-color;
  }
}

.sign-input {
  .form-element-v2__input {
    background-color: rgba($primary-v2, 0.05);

    &:focus:not(:disabled),
    &:hover:not(:disabled),
    &:active:not(:disabled) {
      background-color: $white-v2;
    }
  }
}

.select-chevron-icon {
  position: absolute;
  right: 1rem;
  top: 1.25rem;
  pointer-events: none;
  color: $grey-light-v2;

  &--md {
    top: 1rem;
  }

  &--sm {
    top: 0.75rem;
    right: 0.75rem;
  }

  &:hover {
    cursor: pointer;
  }
}

.InputFromTo {
  display: flex;

  .DayPickerInput {
    position: relative;
    min-width: 10.375rem;
  }

  &__separator {
    display: flex;
    align-items: center;
    margin: 0.5rem;
  }
}
