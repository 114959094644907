@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

.reset-callback-form-V2 {
  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    @include header-1-v2;

    margin-bottom: 2rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__invalid {
    &__title {
      @include header-1-v2;

      margin-bottom: 2rem;
    }
  }
}
