@import "../assets/styles/variables";
@import "../assets/styles/mixins";

:root {
  --primary-color: #e4a85a;
}

body {
  color: black;
  line-height: 1.5;
}

a {
  color: $text-primary-color;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.2s ease;

  &:not(.button-FL, .link-button-FL, .button-FL-V2, .nav-market__company-container, .no-hover):hover {
    color: var(--primary-color);
  }
}

.color-primary {
  color: var(--primary-color);
}

.notification-dot {
  height: 7px;
  width: 7px;
  margin-bottom: 1px;
  display: inline-block;
  border-radius: 4px;
  background-color: #e45a5a;
}

.notification-dot-V2 {
  height: 0.5rem;
  width: 0.5rem;
  min-width: 0.5rem;
  margin-left: 0.5rem;
  display: inline-block;
  background-color: $secondary-v2;
  border-radius: $border-regular-radius;
}

.text-white-color {
  color: white;
}

.text-success-color {
  color: $success-color;
}

.text-warning-color {
  color: $warning-color;
}

.text-error-color {
  color: $danger-color;
}

.fill-custom-color {
  fill: var(--primary-color);
}

.text-font-medium {
  font-family: Scto-Grotesk-A-Medium;
}

.text-font-bold {
  font-family: Scto-Grotesk-A-Bold;
}

.text-subtitle {
  color: $text-secondary-color;
}

.text-paragraph {
  font-size: 0.875rem;
}

.horizontal-separator-absolute {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  border-bottom: 1px solid $border-gray-color;
}

.horizontal-separator {
  width: 100%;
  border-bottom: 1px solid $border-gray-color;
  margin: 1rem 0;
}

.wysiwyg-content {
  ul {
    list-style: disc;
  }

  li {
    display: list-item;
    margin-left: 1em;
  }
}

.wysiwyg-content-v2 {
  // TODO V2 : Delete this line after merge V2
  @include body-v2;

  color: $grey-darker-v2;

  ul {
    list-style: disc;
  }

  li {
    display: list-item;
    margin-left: 1em;
  }
}

.basket-item-quantity__chip {
  margin-left: 0.25rem;
  background-color: var(--primary-color);
  font-size: 0.75rem;
  border-radius: 100%;
  width: 1.125rem;
  height: 1.125rem;
  color: white !important;
  text-align: center;
}
