@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

.signup-company-admin-success-V2 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__title {
    @include header-1-v2;

    margin-bottom: 1rem;
  }

  &__subtitle {
    @include body-regular-v2;

    margin-bottom: 2rem;
  }
}

.signup-company-form-V2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__title {
    @include header-1-v2;

    margin-bottom: 1rem;
  }

  &__subtitle {
    @include body-regular-v2;

    margin-bottom: 2rem;
  }
}
